<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <a-modal :footer="null" :closable="false" width="480px" v-model:open="open" @ok="handleOk"
        wrapClassName="friendly_reminder_modal">
        <div class="friendly_reminder_modal_content">
            <img src="../../assets/home/buy_img_03.png" alt="" class="buy_gb_img" @click="handleOk" />
            <img src="../../assets/home/buy_img_02.png" alt="" class="buy_bg_img" />
            <div class="title">
                <span>「 温馨提示 」</span>
            </div>
            <div class="content">
                <span>{{ content }}</span>
            </div>
            <div class="button" @click="handleOk">
                <a-button type="primary">{{ buttonText }}</a-button>
            </div>
        </div>
    </a-modal>
</template>
<script>
import { ref } from 'vue';
import {
    useRouter
} from 'vue-router';
export default {
    setup() {
        const router = useRouter();
        const open = ref(false);
        const content = ref('');
        const buttonText = ref('知道了');
        const buttonType = ref(0);

        const openInit = (data) => {
            content.value = data.text;
            buttonText.value = data.buttonText || '知道了';
            buttonType.value = data.buttonType;
            open.value = true;
        };

        const handleOk = () => {
            const userData = JSON.parse(localStorage.getItem('user_data') || `{}`);
            if (userData.roleType == 2) {
                router.push('/enterpriseCertification?applyWay=2');
            } else {
                router.push('/personalCertification?applyWay=2');
            }
            open.value = false;
        };

        return {
            open,
            content,
            buttonText,
            handleOk,
            openInit
        }
    }
}
</script>
<style lang="scss">
.friendly_reminder_modal {

    .ant-modal-content {
        position: relative;
        padding: 0;
    }

    .friendly_reminder_modal_content {
        height: 242px;

        .buy_gb_img,
        .buy_bg_img {
            position: absolute;
            z-index: 1;
        }

        .title,
        .content,
        .button {
            position: relative;
            z-index: 10;
        }

        .buy_gb_img {
            width: 16px;
            height: auto;
            top: 16px;
            right: 24px;
            z-index: 20;
            cursor: pointer;
        }

        .buy_bg_img {
            width: 480px;
            height: auto;
            top: -78px;
            left: 0;
            border-radius: 4px;
        }

        .title {
            height: 28px;
            font-weight: 500;
            font-size: 16px;
            color: #323233;
            padding-top: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content {
            color: #323233;
            line-height: 22px;
            margin-top: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .button {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-btn {
                width: 200px;
                height: 32px;
                background: #1677FF;
            }
        }
    }
}
</style>